<template>
  <div >
    <vue-headful :title="pageTitle"/>
    <div id="body-content-area-all" style="overflow: hidden">
      <div class="card">
        <div class="has-text-centered heading" :style="{ backgroundColor: '#ededed', marginBottom: '0px', width: '100%', height: '52px'}">
          <h1 class="is-size-6" style="padding: 12px 0px"><b>{{ pageName.toLocaleUpperCase() }}</b></h1>
          <div class="add-button-container" style="float: right; width: 215px;  position: absolute; right: .5rem; top: .5rem;">
          <a :class="['button is-accent']" @click="openModal">
              <span class="icon">
                <i class="fal fa-plus-square"></i>
              </span>
              <span>
                  Add Document(s)
              </span>
            </a>
          </div>
        </div>
        <div id="pagelayout" style="height: 87.5%;">
          <Grid
            style="height: 100%;"
            v-if="documents && documents.length > 0"
            :filter="filter"
            :data-items="documents"
            :sortable="false"
            :sort="sort"
            :filterable="false"
            :columns="staticColumns"
            :skip="skip"
            :take="take"
            :total="totalRecords"
            :pageable="false"
            :style="{ height: '108%'}"
            @selectionchange="selectionchange"
            @headerselectionchange="headerselectionchange"
            @filterchange="filterchange"
            @sortchange="sortchange"
            @pagechange="pagechange"
            >
              <template v-slot:NameCell="{props}">
                <td style="padding: 0;" class="editable">
                  <v-popover
                    style="height: 100%; width: 75%; text-overflow: clip!important;"
                    :open="props.dataItem.showSaveDialog"
                    :placement="'right-end'"
                    :trigger="'manual'"
                    :autoHide="false"
                  >
                    <div class="">
                      <input
                        style="height: 100%; width: 100%; text-overflow: clip!important;"
                        v-if="isOwnedByUser(props.dataItem)" type="text"
                        v-on:blur="props.dataItem.showSaveDialog = ($event.target.value !== props.dataItem.displayName); props.dataItem.event = $event; props.dataItem.displayName = $event.target.value"
                        v-on:keyup.esc="reset($event, props.dataItem); props.dataItem.event = null; props.dataItem.showSaveDialog = false;"
                        v-on:keyup.enter="props.dataItem.showSaveDialog = ($event.target.value !== props.dataItem.displayName); props.dataItem.event = $event; props.dataItem.displayName = $event.target.value"
                        :value="props.dataItem.displayName"
                      >
                      <input v-else type="text" @mousedown.prevent="" :value="props.dataItem.displayName">
                    </div>

                    <!-- This will be the content of the popover -->
                    <template slot="popover" id="popover">
                      <div class="popover-contain">
                        Are you sure?
                        <div class="actions">
                          <button cancel @click.prevent="reset($event, props.dataItem); props.dataItem.event = null; props.dataItem.showSaveDialog = false;">Cancel</button>
                          <button @click.prevent="submitChanges(props.dataItem, 'displayName'); props.dataItem.event = null; props.dataItem.showSaveDialog = false;">Save</button>
                        </div>
                      </div>
                    </template>
                  </v-popover>
                </td>
              </template>

              <template v-slot:TypeCell="{props}">
                <td>
                  {{props.dataItem.extension.replace('.', '').toUpperCase()}}
                </td>
              </template>

              <template v-slot:SizeCell="{props}">
                <td style="text-align: right;">
                  {{ getFileSize(props.dataItem.fileSize) }}
                </td>
              </template>

              <template v-slot:UploadDateCell="{props}">
                <td>
                  {{ getUploadDate(props.dataItem.uploadDate).format('MM/DD/YYYY') }}
                </td>
              </template>

              <template v-slot:UploadedByCell="{props}">
                <td>
                  {{ props.dataItem.uploadUserName }}
                </td>
              </template>

              <template v-slot:SharingCell="{props}">
                <td class="editable" v-if="isOwnedByUser(props.dataItem)">
                  <select @change="submitChanges(props.dataItem, 'isShared', $event.target.value);">
                      <option :value="true" :selected="props.dataItem.isShared">Shared</option>
                      <option :value="false" :selected="!props.dataItem.isShared">Private</option>
                  </select>
                </td>
                <td class="editable" v-else>
                  <select @mousedown.prevent="" ref="isSharedDropdown">
                      <option :selected="true">{{props.dataItem.isShared ? 'Shared' : 'Private'}}</option>
                  </select>
                </td>
              </template>

              <template v-slot:ActionCell="{props}">
                <td style="width: 100%; padding: 0;">
                  <div class="action-cell">
                    <div v-tooltip.left-start="{ content: 'Download Document', container: false, classes: ['tooltip'] }">
                      <a :href="getDocumentPath(props.dataItem)" download><i class="fa fa-download"></i></a>
                    </div>
                    <div v-tooltip.left-start="{ content: 'View Document', container: false, classes: ['tooltip'] }">
                      <a href="" @click.prevent="viewDocument(props.dataItem)"><i class="fa fa-eye"></i></a>
                    </div>
                    <div v-tooltip.left-start="{ content: 'Delete Document', container: false, classes: ['tooltip'] }">
                      <a v-if="isOwnedByUser(props.dataItem)" href="" @click.prevent="deleteDocument(props.dataItem)"><i class="fa fa-trash"></i></a>
                    </div>
                  </div>
                </td>
              </template>
          </Grid>
          <div v-else>
            <center>No documents</center>
          </div>
        </div>
      </div>
      <form @submit.prevent="submitFiles">
        <modal @close="removeFiles()" ref="upload">
          <template v-slot:header></template>
          <template v-slot:body>
            <div v-if="filePondIsLoading"></div>
            <div ref="filePondWrapper" :class="['filePondWrapper', $refs.filePond && $refs.filePond.getFiles().length < 1 ? 'has-no-files' : '']" @click.stop="openFileBrowser">
              <file-pond
                name="file"
                ref="filePond"
                :label-idle="filePondLabel"
                @click.stop=""
                allow-multiple="true"
                accepted-file-types="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :files="input.files"
                v-on:addfilestart="addfile"
                v-on:removefile="removefile"
                :dropOnElement="false"
                :dropOnPage="true"
                :credits="[]"
              />
            </div>
          </template>
          <template v-slot:footer v-if="input.files.length !== 0">
            <div class="modal-footer">
              <button :class="[isSaving ? 'saving' : '']" ref="saveButton" type="submit" :disabled="isSaving">
                {{ isSaving ? 'Saving' : 'Save Document' }}
                <spin-loader v-if="isSaving" />
              </button>
            </div>
          </template>
        </modal>
      </form>

      <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
          <div class="modal-background" @click="showDeleteModal = false"></div>
          <div class="modal-card">
          <header class="modal-card-head has-bg-danger">
              <div class="modal-card-title has-bg-danger">Confirm Delete</div>
              <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
          </header>
          <section class="modal-card-body">
              <div class="content">
              Type "<b>delete</b>" to confirm that you want to delete "{{ selectedFile.name }}"
              </div>
              <div class="field">
              <div class="control">
                  <input
                  type="text"
                  class="input"
                  placeholder="type `delete` and then click confirm"
                  v-focus
                  v-model.trim="confirmationText"
                  @keydown.enter.prevent="deleteIfConfirmed"
                  />
              </div>
              </div>
          </section>
          <footer class="modal-card-foot">
              <a
              class="button is-danger"
              @click="deleteIfConfirmed"
              :disabled="confirmationText !== 'delete'"
              >
              <span class="icon">
                  <i class="fal fa-trash-alt"></i>
              </span>
              <span>Confirm</span>
              </a>
              <a class="button" @click="showDeleteModal = false">Cancel</a>
          </footer>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { Grid } from '@progress/kendo-vue-grid'
// import { orderBy, filterBy } from '@progress/kendo-data-query'
import vueFilePond, { setOptions } from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import debounce from 'debounce'
import Modal from '../components/partials/Modal.vue'
import { Toast } from 'buefy/dist/components/toast'
import axios from '../axios'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
// import debounce from 'debounce'

// <v-popover
//   :auto-hide="false"
//   open-group="group1"
// >
//   <!-- ... -->
// </v-popover>

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileRename
)

// let uploadedFiles = []
const setCompOptions = (that) => {
  setOptions({
    fileRenameFunction: (file) => {
      return `${file.name.replace(/[/\\?%*–:|"<>]/g, '_').trim()}`
    },
    allowRevert: false,
    allowProcess: false,
    instantUpload: false,
    server: {
      instantUpload: false,
      url: `${process.env.VUE_APP_API_URL}`,
      process: {
        url: `/documents/clients`,
        method: 'POST',
        withCredentials: true,
        instantUpload: false,
        onload: response => {
          let r = JSON.parse(response)
          that.uploadedFiles.push(r)
          return r.id
        },
        ondata (payload) {
          payload.append('YearOverride', new Date().getFullYear())
          return payload
        },
        onerror: response => {
          let res = JSON.parse(response)
          let msg = `Upload Error: ${res.message}`
          Toast.open(msg, 'is-danger', 'is-top', 5000)
        }
      },
      revert: (uniqueFileId, load, error) => {
        let path = `/documents/clients/${uniqueFileId}`
        axios
          .delete(path)
          .then(res => {
            load()
          })
          .catch(err => {
            error(err)
          })
      },
      fetch: null,
      restore: null,
      load: null
    }
  })
}

export default {
  name: 'SearchScreen',
  props: {
    query: String
  },
  components: {
    'Grid': Grid,
    Modal,
    FilePond
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      uploadedFiles: [],
      show: false,
      lockFileBrowser: false,
      confirmationText: null,
      isSaving: false,
      reportTypeCodes: null,
      documents: [],
      documentsOriginal: null,
      filePondIsLoading: true,
      filePondLabel: `<div style='color: #7fb942; padding-top: 3rem;'><div><span class='icon bcc-green' style='font-size: 5rem;'><i class='fas drop fa-3x fa-cloud-upload-alt' style='max-width: 50px!important; max-height: 50px!important; font-size: 2rem!important;'></i></span></div><div>Click to choose files to upload, or drop to upload them to the portal instantly</div></div>`,
      input: {
        id: '',
        name: '',
        displayName: '',
        typeCode: '',
        typeCodeDescription: '',
        institutionId: '',
        institutionName: '',
        yearOverride: '',
        extension: '',
        uploadDate: '',
        fileSize: 0,
        sendEmail: false,
        emailTemplateId: 0,
        files: []
      },
      showDeleteModal: false,
      selectedFile: null,
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      perPage: 20,
      page: 1,
      institution: null,
      pageable: false,
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'displayName',
          title: 'Document Name',
          width: '320px',
          cell: 'NameCell'
        },
        {
          field: 'extension',
          title: 'Type',
          width: '130px',
          cell: 'TypeCell'
        },
        {
          field: 'fileSize',
          title: 'Size',
          cell: 'SizeCell'
        }
      ],
      sort: [
        { field: 'name', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: [
        ]
      }
    }
  },
  watch: {
    input: {
      handler (oldFile, newFile) {
        if (newFile.files.length !== 0) {
          this.filePondLabel = `<div style='color: #7fb942; padding-top: 3rem;'><div>Click to add more files</div></div>`
        } else {
          this.filePondLabel = `<div style='color: #7fb942; padding-top: 3rem;'><div><span class='icon bcc-green' style='font-size: 5rem;'><i class='fas drop fa-3x fa-cloud-upload-alt' style='max-width: 50px!important; max-height: 50px!important; font-size: 2rem!important;'></i></span></div><div>Click to choose files to upload, or drop to upload them to the portal instantly</div></div>`
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['clientSession', activeSite]),
    pageTitle () {
      if (this.institution) {
        return (this.pageName + ' - ' + this.institution.name + ' ' + (parseInt(this.activeSite.id) === 1 ? 'BOLI Portal' : 'COLI Portal'))
      }

      return ''
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalDocuments
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  async created () {
    setCompOptions(this)

    if (this.activeSite.id === 1) {
      this.pageName = 'Bank Uploads'
    } else {
      this.pageName = 'Business Uploads'
    }

    await this.GetInstitution()
    await this.getReportTypes()
  },
  mounted () {
    // this.getDocuments()

    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight

        // this.getDocuments()
      }

      that.getListHeights('pagelayout', 55, 49, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('pagelayout', 55, 49, elements, func.bind(that))
      })
    })
  },
  methods: {
    async getReportTypes () {
      const that = this
      this.axios.get(`/memberships/${this.clientSession.userId}`)
        .then(function (response) {
          if (response.status === 200) {
            that.reportTypeCodes = response.data.reportTypeCodes

            if (!that.reportTypeCodes.includes('USR')) {
              that.$router.push({ name: 'Dashboard' })
            }

            that.show = true
          }
        })
    },
    removeFiles () {
      let that = this
      this.$refs.filePond.getFiles().forEach(function (current) {
        that.$refs.filePond.removeFile(current, {})
      })

      this.input.files = []
    },
    openFileBrowser () {
      if (this.$refs.filePond.getFiles().length > 0) {
        debounce(this.$refs.filePond.browse(), 500)
      }
    },
    async GetInstitution () {
      try {
        let that = this
        this.isLoading = true
        let route = `/institutions/${this.clientSession.institutionId}/`
        this.axios.get(route)
          .then(function (response) {
            if (response.status >= 200 && response.status < 300) {
              that.institution = response.data
            }
          })
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    cloneDocuments () {
      return this.clone(this.documents, true)
    },
    selectionchange () {
      // stuff
    },
    headerselectionchange () {
      // stuff
    },
    filterchange () {
      // stuff
    },
    sortchange (e) {
      this.sort = e.sort
      this.getDocuments()
    },
    async submitChanges (original, type, newValue) {
      let originalValue = this.documentsOriginal.filter(_document => _document.id === original.id)

      if (originalValue.length > 0) {
        let payload = new FormData()
        let keys = Object.keys(original)

        keys.forEach(function (key) {
          if (key === 'uploadDate') {
            var result = new Date(original[key])
            result.setDate(result.getDate() + 1)
            payload.append(key, result.toDateString())
          } else if (key === 'isShared') {
            payload.append(key, newValue) // just toggle it.
          } else {
            payload.append(key, original[key])
          }
        })

        let response = await this.axios.put('documents/clients/' + original.id, payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response.status >= 200 && response.status < 300) {
          this.getDocuments()
        }
      }

      this.$forceUpdate()
    },
    reset (event, value) {
      value.showSaveDialog = false
      event.target.blur()
      this.getDocuments()
    },
    pagechange (e) {
      this.take = e.page.take
      this.skip = e.page.skip

      this.getDocuments()
    },
    openModal () {
      this.$refs.upload.openModal()
      this.restructure()
    },
    restructure () {
      let that = this
      setTimeout(function () {
        let filePondRoot = that.$refs.filePondWrapper.querySelector('.filepond--root')
        let filePondPanel = that.$refs.filePondWrapper.querySelector('.filepond--panel-root')

        filePondRoot.style.height = '500px'
        filePondPanel.style['background'] = 'white!important'

        that.filePondIsLoading = false
      }, 500)
    },
    removefile (arg, file) {
      let that = this

      this.input.files = this.input.files.filter(_file => _file.file.name !== file.file.name)
      // Do not add the same file twice.
      this.$refs.filePond.getFiles().forEach(function (current) {
        if (current.id === file.id) {
          that.$refs.filePond.removeFile(current, {})
        }
      })
    },
    addfile (file) {
      let that = this
      // Do not add the same file twice.
      this.$refs.filePond.getFiles().forEach(function (current) {
        if (current.id !== file.id && current.file.name === file.file.name) {
          that.$refs.filePond.removeFile(current, {})
        }
      })

      this.input.files = this.$refs.filePond.getFiles()
    },
    isOwnedByUser (file) {
      return parseInt(file.uploadUserId) === parseInt(this.clientSession.userId)
    },
    async submitFiles () {
      // let failed = []
      let that = this

      this.isSaving = true

      let payload = new FormData()
      payload.append('file', {})
      payload.append('YearOverride', new Date().getFullYear())
      payload.append('UploadDate', new Date().toUTCString())
      this.input.files = this.$refs.filePond.getFiles()

      this.$refs.filePond.processFiles().then(() => {
        this.isSaving = false

        that.$refs.filePond.getFiles().forEach(function (current) {
          that.documents.push({
            ...current,
            displayName: current.file.name,
            fileSize: current.fileSize,
            extension: current.fileExtension
          })
          that.$refs.filePond.removeFile(current, {})
        })

        this.$http.post('/documents/clients/notify-clients', {
          DocumentIds: that.uploadedFiles.map(_file => {
            return _file.id
          })
        })

        that.$refs.upload.closeModal()
      })
    },
    viewDocument (document) {
      this.$router.push({
        name: 'ViewDocumentScreen',
        params: {
          id: document.id,
          document: document
        }
      })
    },
    deleteDocument (document) {
      this.selectedFile = document
      this.showDeleteModal = true
    },
    async deleteIfConfirmed () {
      let response = await this.axios.delete('documents/clients/' + this.selectedFile.id)

      if (response.status >= 200 && response.status < 300) {
        this.showDeleteModal = false
        this.confirmationText = ''
        this.getDocuments()
      }
    }
  }
}
</script>

<style scoped>
.k-grid td:first-child {
  color: #424242!important;
}

.k-grid th:first-child {
  color: #424242!important;
}
.filepond--root {
  height: 500px!important;
}
input, select {
-webkit-appearance: none;
-webkit-border-radius: 0;
color: inherit;
font-size: inherit;
}

  .add-button-container {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-bottom: 1rem;
  }

  .add-button {
    float: right;
    background: #7fb841;
    border-radius: 0.125rem;
    /* -webkit-box-shadow: 0px 0px 4px 1px #AEAEAE;
    box-shadow: 0px 0px 4px 1px #AEAEAE; */
    color: white;
    padding: 5px 8px 5px 8px;
    cursor: pointer;
  }

  .add-button:hover {
    background: #93c65d;
  }

  .add-button i.fa {
    font-size: 12px!important;
    margin: auto;
  }

  .editable:hover {
    -webkit-box-shadow: 0px 0px 4px 1px #AEAEAE;
    box-shadow: 0px 0px 4px 1px #AEAEAE;
    cursor: pointer;
  }

  .editable button {
    visibility: hidden;
    background: white;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    color: #7fb841;
    padding: 5px;
    padding-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .saving {
    background: #d9d9d9!important;
  }

  .editable input {
    width: 100%;
  }

  .editable select {
    width: 100%;
  }

  .editable input:focus ~ button {
    visibility: visible;
  }

  select {
    appearance: none;
    padding: 5px;
    border: none;
    font-family: inherit;
    outline: none;
  }

  .editable input, .editable select {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }

  #pagelayout {
  width: 100%;
  padding: 25px;
}

.card {
  height: 100%;
}

#app-body-area[activelayout="client-facing"] #body-content-area-all {
  height: calc(100vh - 86px - 68px)!important;
  margin: 0 auto;
  width: 100%!important;
  padding: 1rem!important;
}

.modal-background {
  z-index: 40;
}

.modal-card {
  z-index: 50;
}
#app-body-area[activelayout="osr-facing"] #body-content-area-all {
  height: calc(100vh - 52px - 86px - 78px)!important;
  margin: 0 auto;
  width: 100%!important;
  padding: 1rem!important;
}

  .editable input:focus {
    outline: none;
    cursor: text;
  }

  .action-cell {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 25px;
  }

  @media (min-width: 800px) {
    td .action-cell a {
      display: none;
      margin: 0 .25rem;
    }
  }

  tr:hover .action-cell a {
    display: inline-block;
    transition: all 1s ease-out;
  }

  .filepond--drop-label {
    display: none!important;
  }

  .filepond--credits {
    display: none!important;;
  }

  .filepond--root {
    height: 500px!important;
  }

  .filepond--panel-root {
    background-color: white!important;
  }

  i.fas .drop {
    font-size: 3rem !important;
    min-width: 50px !important;
    max-width: 50px !important;
    padding: 0px !important;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .modal-footer > button {
    padding: .5rem;
    background: #7fb942;
    color: white;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif!important;
  }

  .link:hover {
    color: #9cbd78;
    cursor: pointer;
  }

  .modal-footer > button:hover {
    background: #9cbd78;
  }

  .tooltip {
    font-size: 12px!important;
    padding: 5px!important;
  }
.filepond--panel-root {
    background-color: #eee!important;
}

.filepond--drop-label {
  font-family: 'Poppins', sans-serif!important;
    position: absolute;
    bottom: 0;
    top: auto;
    padding-bottom: 3rem;
}

.filepond--list-scroller {
  position: absolute;
  top: 0;
  transform: translate3d(0px, 0px, 0px)!important;
}

.filepond--drip-blob {
    background-color: #999;
}
/* bordered drop area */
.filepond--panel {
    background-color: #fff!important;
    border: 2px solid #fff;
}

.popover-contain {
  padding: .25rem;

  z-index: 50;
}

.tooltip .tooltip-inner {
  background: white!important;
  color: #656565;
  padding: 0rem;

  border-radius: .25rem;
}

.tooltip  {
  background: white!important;
  padding: 0rem;

  border-radius: .25rem;
  border: 1px solid #e2e2e2;
}

.message {
  background: white;
}

.actions {
  margin-top: .5rem;
  display: flex;
  justify-content: center;
}

.actions button {
  float: right;
  background: #7fb841;
  border-radius: .25rem;
  border: 0px;
  box-shadow: none!important;
  color: white;
  cursor: pointer;
  margin: .25rem!important;
  padding: .25rem;
}

.actions button[cancel] {
  background: #ff3860!important;
}

.actions button:hover {
  background: #93c65d;
}

.trigger {
  width: 100%;
  padding: 1rem;
}

/* .filepond--drop-label {
  position: relative;
}

.filepond--drop-label label {
  position: absolute;
  bottom: 0;
}

filepond--browser {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 1em;
    top: 1.75em;
    width: calc(100% - 2em);
    opacity: 0;
    font-size: 0;
    min-height: 100%!important;
} */

/* .filepond--root filepond--hopper {
  height: 100%!important;
} */

.filepond--root {
  height: 500px!important;
  max-height: 500px;
}

.filepond--browser {
  cursor: pointer;
}

.filePondWrapper {
  background-color: #eeeeee;
  border-radius: .25rem;
  cursor: pointer;
  margin: 1rem 0rem;
  height: 500px;
  cursor: pointer;
}

.filePondWrapper.has-no-files .filepond--browser {
    position: absolute;
    background-color: #eee!important;
    margin: 0;
    padding: 0;
    left: 1em;
    top: 1.75em;
    width: calc(100% - 2em);
    opacity: 0;
    font-size: 0;
    min-height: 100%!important;
    z-index: 500;
}

.filepond--root > .filepond--panel {
    z-index: 2;
    background-color: #eee !important;
    border-radius: .3rem;
}

.filePondWrapper.has-no-files .filepond--drop-label {
  position: absolute!important;
  bottom: 0!important;
}

</style>
